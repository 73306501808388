import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import JobLayout from '../layouts/jobs';
import InstitutionLayout from '../layouts/institution';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([
    {
      // path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            // <GuestGuard>
            //   <Register />
            // </GuestGuard>
            <Register />
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-confirm-password', element: <ResetConfirmPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'project-preview', element: <ProjectPreview /> },
      ],
    },
    // User Profile
    // {
    //   // path: '/:userId',
    //   path: '/:location',
    //   children: [
    //     {
    //       path: '',
    //       element: <LocationDetailsPage />,
    //     },
    //   ],
    // },
    {
      path: '/:location',
      children: [
        // { element: <Navigate to="/:location/department" replace />, index: true },
        { path: '', element: <LocationDetailsPage /> },
        { path: 'department', element: <DepartmentLd /> },
        { path: 'courses', element: <CourseLd /> },
        { path: 'contact', element: <ContactLd /> },
        { path: 'admission', element: <AdmissionLd /> },
        { path: 'placement', element: <PlacementLd /> },
        { path: 'faculty', element: <FacultyLd /> },
        { path: 'gallery', element: <GalleryLd /> },
        { path: 'courses/:courseId', element: <DetailedCourse /> },
        { path: 'admission/:admissionId', element: <DetailedAdmission /> },
      ],
    },
    {
      // path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'profile', element: <ProfilePageMain /> },
        // {
        //   path: 'setting',
        //   element: <SettingPageMain />,
        // },

        // {
        //   path: 'institution',
        //   element: <InstitutionMain />,
        // },
        // { path: 'InstitutionDashboard/:Id', element: <ViewInstitutionDashboard /> },
        {
          path: 'institution',
          children: [
            { element: <Navigate to="/institution/courses" replace />, index: true },
            { path: 'courses', element: <InstitutionCourse /> },
            { path: 'faculty', element: <InstitutionFaculty /> },
            { path: 'admission', element: <InstitutionAdmission /> },
            // { path: 'InstitutionDashboard/:Id', element: <ViewInstitutionDashboard /> },
          ],
        },
        {
          path: 'placement',
          children: [
            { element: <Navigate to="/placement/incharge" replace />, index: true },
            { path: 'incharge', element: <PlacementIncharge /> },
            { path: 'student-database', element: <PlacementStuDatabase /> },
            { path: 'student-details', element: <PlacementStuDetails /> },
          ],
        },
        {
          path: 'dashboard',
          element: <GeneralApp />,
        },
        {
          path: 'search',
          children: [
            { element: <Navigate to="/search/talentSearch" replace />, index: true },
            { path: 'talentSearch', element: <TalentSearchMains /> },
            { path: 'talentSearchResult/:flag/:searchParameters', element: <ListOfTalent /> },
            { path: 'talentSearchMain/:flag/:searchId/:searchParameters', element: <SavedSearchShow /> },
          ],
        },
        {
          path: 'setting',
          children: [
            { element: <Navigate to="/setting/insProfile" replace />, index: true },
            // { path: 'insProfile', element: <SettingPageMain /> },
            { path: 'insProfile', element: <SettingProfile /> },
            { path: 'mea_category', element: <SettingMEA /> },
            { path: 'account', element: <SettingAccount /> },
            { path: 'notification', element: <SettingNotification /> },

            { path: 'profileVisibility', element: <SettingProfileVisibility /> },

            { path: 'manageAccount', element: <SettingManageAccount /> },
            { path: 'deleteAccount', element: <SettingDeleteAccount /> },
            // { path: 'subscription/:name', element: <SubscriptionPlans /> },
            { path: 'subscription', element: <SubscriptionPlans /> },
            // { path: 'subscription/plans', element: <SubscriptionPlans /> },
            // { path: 'subscription/subscriptionTable', element: <SubscriptionTable /> },
            { path: 'subscription/billingInfo', element: <BillingInfo /> },
            { path: 'subscription/invoiceDetails/:InvId', element: <InvoiceDetails /> },
            // { path: 'subscription/invoiceDetails', element: <InvoiceDetails /> },
            { path: 'payment', element: <SettingPayment /> },
          ],
        },
        {
          path: 'connectnetwork',
          element: <Connect />
        },
        {
          path: 'network',
          children: [
            // { path: 'followers', element: <Followers /> },
            // { path: 'following', element: <Following /> },
            {
              path: "followers",
              children: [
                { element: <Navigate to="/network/followers/companies" replace />, index: true },
                { path: "talents", element: <TalentsFollowers /> },
                { path: "companies", element: <CompaniesFollowers /> },
                { path: "institution", element: <InstitutionFollowers /> },

              ],
            },
            {
              path: "following",
              children: [
                { element: <Navigate to="/network/following/companies" replace />, index: true },
                { path: "talents", element: <TalentsFollowing /> },
                { path: "companies", element: <CompaniesFollowing /> },
                { path: "institution", element: <InstitutionFollowing /> },

              ],
            },
          ],
        },
        // { path: 'placement', element: <PlacementMain /> },
        { path: 'calender', element: <Calender /> },
        // { path: 'jobsearch', element: <JobsearchMain /> },
        {
          path: 'jobs',
          children: [
            // { element: <Navigate to="/jobsearch" replace />, index: true },
            { path: '', element: <JobsearchMain /> },
            // { path: 'search', element: <QuickSearchList /> },
            { path: 'search/:flag/:searchparameters', element: <QuickSearchList /> },

          ]
        },
        {
          path: '/activityLog',
          element: (
            <ActivityLogPage />
          ),
        },

        // {
        //   path: 'inbox',
        //   children: [
        //     { path: '', element: <Inbox /> },
        //     { path: 'notification', element: <Notifications /> },
        //     { path: 'enquiry', element: <Enquiry /> },
        //   ],
        // },
        {
          path: 'inbox',
          children: [
            { path: 'talent', element: <TalentInbox /> },
            { path: 'talent/employer', element: <EmployerInbox /> },
            { path: 'talent/notification', element: <Notifications /> },
            { path: 'talent/enquiry', element: <Enquiry /> },
          ],
        },
        // { path: 'website', element: <WebsiteAboutUs /> },
        {
          path: 'website',
          children: [
            { element: <Navigate to="/website/about" replace />, index: true },
            { path: 'about', element: <WebsiteAboutUs /> },
            { path: 'banners', element: <WebsiteBanners /> },
            { path: 'services', element: <WebsiteServices /> },
            { path: 'theme', element: <WebsiteThemes /> },
            { path: 'setting', element: <WebsiteSetting /> },
            { path: 'enquiry', element: <WebsiteEnquiry /> },
            { path: "socialmedia", element: <WebsiteSocialMedia /> },
            { path: 'gallery', element: <WebsiteGallery /> },
            { path: 'gallery/image-upload', element: <WebsiteGalleryImageUpload /> },
            { path: 'gallery/album-view', element: <WebsiteGalleryAlbumView /> },
          ],
        },
        { path: 'subscription', element: <Subscription /> },
      ],
    },
    {
      path: '/institution',
      element: <InstitutionLayout />,
      children: [
        { path: 'InstitutionDashboard/:Id/:courseId', element: <ViewInstitutionDashboard /> },
      ]
    },

    {
      path: '/',
      element: <Login />,
      children: [{ element: <HomePage />, index: true }],
    },
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetConfirmPassword = Loadable(lazy(() => import('../pages/auth/ResetConfirmPass')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const PortFolio = Loadable(lazy(() => import('../pages/ComingSoon')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const LocationDetailsPage = Loadable(lazy(() => import('../pages/dashboard/LocationDetails')));
const CourseLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Courses/CourseListMain')));
const DetailedCourse = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Courses/DetailedCourse')));
const DetailedAdmission = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Admission/DetailedAdmission')));
const AdmissionLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Admission/AdmissionListMain')));
const DepartmentLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Department/DepartmentListMain')));
const AboutUsLDLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/AboutUsLD')));
const ContactLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/FooterLD')));
const PlacementLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Placement/PlacementMain')));
const GalleryLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Gallery/GalleryList')));
const FacultyLd = Loadable(lazy(() => import('../pages/dashboard/LocationDetailsChildren/Faculty/FacultyMain')));
// const SettingPageMain = Loadable(lazy(() => import('../pages/dashboard/SettingPage')));
const InstitutionMain = Loadable(lazy(() => import('../pages/dashboard/Institution/InstitutionPage')));
const InstitutionCourse = Loadable(lazy(() => import('../pages/dashboard/Institution/Children/Courses')));
const InstitutionFaculty = Loadable(lazy(() => import('../pages/dashboard/Institution/Children/Faculty')));
const InstitutionAdmission = Loadable(lazy(() => import('../pages/dashboard/Institution/Children/Admissions')));
const PlacementMain = Loadable(lazy(() => import('../pages/dashboard/Placement/PlacementPage')));
const PlacementIncharge = Loadable(lazy(() => import('../pages/dashboard/Placement/Children/Incharge')));
const PlacementStuDatabase = Loadable(lazy(() => import('../pages/dashboard/Placement/Children/StudentDatabase')));
const PlacementStuDetails = Loadable(lazy(() => import('../pages/dashboard/Placement/Children/StudentDetails')));

const Calender = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

const ViewInstitutionDashboard = Loadable(lazy(() => import('../pages/dashboard/Institution/InstitutionDashboardPage')));
const ProfilePageMain = Loadable(lazy(() => import('../pages/dashboard/EmployerPage')));
const TalentSearchMains = Loadable(lazy(() => import('../pages/dashboard/TalentSearchPage')));
const ListOfTalent = Loadable(lazy(() => import('../sections/@dashboard/user/profile/SavedTalentAdvancedSearch')));
const SavedSearchShow = Loadable(lazy(() => import('../sections/@dashboard/user/profile/SavedTalentAdvancedSearch')));
const Followers = Loadable(lazy(() => import('../pages/dashboard/connectnetwork/Followers')));
const Following = Loadable(lazy(() => import('../pages/dashboard/connectnetwork/Following')));
const TalentsFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/Talents")));
const CompaniesFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/Companies")));
const InstitutionFollowers = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/Institutions")));
const TalentsFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/UnTalents")));
const CompaniesFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/UnCompanies")));
const InstitutionFollowing = Loadable(lazy(() => import("../pages/dashboard/connectnetwork/Follow/UnInstitutions")));
// const WebsiteAboutUs = Loadable(lazy(() => import('../pages/dashboard/Website/AboutUs')));
const WebsiteAboutUs = Loadable(lazy(() => import('../sections/@dashboard/user/profile/EmployerOrganisation')));
const WebsiteBanners = Loadable(lazy(() => import('../sections/@dashboard/user/profile/EmployerOrgBanners')));
const WebsiteServices = Loadable(lazy(() => import('../sections/@dashboard/user/profile/EmployerServices')));
const WebsiteGallery = Loadable(lazy(() => import('../sections/@dashboard/user/profile/UserGallery')));
const WebsiteGalleryImageUpload = Loadable(lazy(() => import('../sections/@dashboard/user/WebsiteGallery/GalleryImageUpload')));
const WebsiteGalleryAlbumView = Loadable(lazy(() => import('../sections/@dashboard/user/WebsiteGallery/GalleryAlbumView')));
const WebsiteThemes = Loadable(lazy(() => import('../sections/@dashboard/user/profile/EmployerWebsiteThemes')));
const WebsiteSetting = Loadable(lazy(() => import('../sections/@dashboard/user/profile/EmployerWebsiteSettings')));
const WebsiteEnquiry = Loadable(lazy(() => import('../sections/@dashboard/user/profile/InstituteWebsiteEnquiries')));
const WebsiteSocialMedia = Loadable(lazy(() => import("../sections/@dashboard/user/profile/EmployerWebsiteSocialMedia") ));
const Inbox = Loadable(lazy(() => import('../pages/dashboard/InBox/TalentInbox')));
const Subscription = Loadable(lazy(() => import('../pages/dashboard/InBox/Subscription')));
const Notifications = Loadable(lazy(() => import('../pages/dashboard/InBox/Notifications')));
const Enquiry = Loadable(lazy(() => import('../pages/dashboard/InBox/Enquiry')));
const ProjectPreview = Loadable(lazy(() => import('../pages/dashboard/PreviewProject')));
const Connect = Loadable(lazy(() => import('../pages/dashboard/connectnetwork/Connect')));
const JobsearchMain = Loadable(lazy(() => import('../pages/dashboard/Job/JobSearchPage')));
const QuickSearchList = Loadable(lazy(() => import('../pages/dashboard/Job/Children/SearchList/QuickSearchList')));
// ---------------------------------------
const SettingPageMain = Loadable(lazy(() => import('../pages/dashboard/SettingPage')));
const SettingProfile = Loadable(lazy(() => import('../sections/@dashboard/user/profile/CompanyProfile')));
const SettingMEA = Loadable(lazy(() => import('../sections/@dashboard/user/profile/MEACategorySetting')));
const SettingAccount = Loadable(lazy(() => import('../sections/@dashboard/user/profile/Account')));
const SettingNotification = Loadable(lazy(() => import('../sections/@dashboard/user/profile/Notification')));
const SettingDeleteAccount = Loadable(lazy(() => import('../sections/@dashboard/user/profile/DeleteAccount')));

const SettingProfileVisibility = Loadable(lazy(() => import('../sections/@dashboard/user/profile/ProfileVisibility')));

const SettingManageAccount = Loadable(lazy(() => import('../sections/@dashboard/user/profile/ManageAccount')));

const SubscriptionPlans = Loadable(lazy(() => import('../pages/dashboard/subscription/Subscription')));
const BillingInfo = Loadable(lazy(() => import('../pages/dashboard/subscription/BillingInfo')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/subscription/InvoiceDetails')));
const SubscriptionTable = Loadable(lazy(() => import('../pages/dashboard/subscription/SubscriptionTable')));
const SettingPayment = Loadable(lazy(() => import('../pages/dashboard/subscription/PaymentHistory')));
const ActivityLogPage = Loadable(lazy(() => import('../pages/dashboard/activitylog/ActivityLogPage')));

const TalentInbox = Loadable(lazy(() => import('../pages/dashboard/InBox/TalentInbox')));
const EmployerInbox = Loadable(lazy(() => import('../pages/dashboard/InBox/EmployerInbox')));






