import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import createProfile from './slices/createProfile'
import jobPost from './slices/jobPost';
import settingsCredit from './slices/settings';
import myjobposting from './slices/myjob';
import JobSeek from './slices/JobSeek';
import TalentSearch from './slices/talentSearch';
import companyProfile from './slices/companyProfile';
import Followers from './slices/Followers';
import inbox from './slices/inbox';
import profileIntro from './slices/profileIntro';
import ForgotPass from './slices/ForgotPass';
import Subcriptions from './slices/Subscription';
import talentIndustries from './slices/talentIndustries';
import quickSearchdata from './slices/quickSearchdata';
import AdvanceSearch from './slices/AdvanceSearch';
import saveSearchData from './slices/saveSearchData';
import skillset from './slices/skillset';
// import MyContactReducer from './slices/MyContact';
// import TeamPayments from './slices/teamsPayments';
// import BookmarkedTalentReducer from './slices/BookmarkedTalent';


// ----------------------------------

import InstitutionCourse from './slices/InsCourse'
import InstitutionFaculty from './slices/InsFaculty'
import PlacementStudentDB from './slices/PlacementStudentDb'
import InstitutionDashboardReducer from './slices/InstitutionDashboard';
import LocationDetail from './slices/locationDetails';
import dashboardNotification from './slices/DashboardNotification';
import Activitylog from './slices/activitylog';
import WebsiteGallery from './slices/WebsiteGallery';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};



const rootReducer = combineReducers({
  profile: createProfile,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  settings: settingsCredit,
  postJobs: jobPost,
  myjob: myjobposting,
  jobSeeker: JobSeek,
  talentSearch: TalentSearch,
  companyprofile: companyProfile,
  Follow: Followers,
  inboxed: inbox,
  intro: profileIntro,
  forgotPas: ForgotPass,
  industries: talentIndustries,
  subscription: Subcriptions,
  InsCouse: InstitutionCourse,
  InsFaculty: InstitutionFaculty,
  StudentDatabase: PlacementStudentDB,
  InstitutionDashboard: InstitutionDashboardReducer,
  LocationDetails: LocationDetail,
  DashboardNotification: dashboardNotification,
  Jobsearch: quickSearchdata,
  advanceSearchdata: AdvanceSearch,
  savedSearch: saveSearchData,
  skillsetInd: skillset,
  activitylog: Activitylog,
  websiteGallery: WebsiteGallery
});

export { rootPersistConfig, rootReducer };
