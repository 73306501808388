import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  socialLinkData: [],
  PostProfileIntrolData:[],
  updateEmployeeType:[],
  updateSocailLinksucc:[],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getSocialLinkSucc(state, action) {
      state.isLoading = false;
      state.socialLinkData = action.payload;
    },
    
    postProfileIntroSucc(state, action) {
        state.isLoading = false;
        state.PostProfileIntrolData = action.payload;
      },

      updateEmployeeType(state, action) {
        state.isLoading = false;
        state.updateEmployeeType = action.payload;
      },
      updateSocailLink(state, action) {
        state.isLoading = false;
        state.updateSocailLinksucc = action.payload;
      },
   
   // CREATE EVENT
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getSocialLinkData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.get('/get_social_link/');
      dispatch(slice.actions.getSocialLinkSucc(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function SaveSocialLinkType(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/save_social_link/',data);

      dispatch(slice.actions.updateSocailLink(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SaveEmployeeType(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const tokenVal = window.localStorage.getItem("accessToken")
    try {
      const response = await axios.post('/save_employer_type/',data);

      dispatch(slice.actions.updateEmployeeType(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ClearSkilSetData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateEmployeeType([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}


export function ClearSaveSocailData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.updateSocailLink([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}


export function CleargetSocailData(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getSocialLinkSucc([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
};
}


